@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

* {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1280px) {
  .base-page {
    padding: 0 30px 0 30px;
    transition: 0.2s;
  }

  .website-navbar {
    height: 70px !important;
  }

  .website-navbar > #spacer, #logo {
    display: none;
  }

  .webiste-name {
    display: none;
  }

  .website-navbar-text {
    display: none;
  }

  .website-navbar-icon {
    font-size: 30px !important;
    display: block !important;
  }
}

.grid-container-4 {
  --grid-layout-gap: 25px;
  --grid-column-count: 4;
  --grid-item--min-width: 200px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);
}

.base-page {
  margin: 40px auto;
  max-width: 1280px;
  transition: 0.2s;
}

.website-navbar {
  color: white;
  background-color: #ea4935;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.website-navbar > div {
  display: inline-block;
}

.website-navbar a {
  color: white;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  margin: 0 20px;
  transition: 0.2s;
}

.website-navbar a:hover {
  filter: brightness(95%);
  transition: 0.2s;
}

.website-navbar .webiste-name {
  font-weight: bold;
  font-size: 30px;
}

.website-navbar-icon {
  display: none;
}

.website-button {
  display: inline-block;
  border-radius: 20px;
  padding: 10px 20px;
  transition: 0.2s;
  border: none;
  text-decoration: none !important;
}

.website-button:hover {
  filter: brightness(95%);
  transition: 0.5s;
}

.website-hover {
  transition: 0.2s;
}

.website-hover:hover {
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 10px 1px !important;
  transition: 0.2s;
}

.website-footer {
  background-color: gainsboro;
}

.website-link-hover:hover {
  color: #ea4935 !important;
}